import React from "react";

export const Testimonials = (props) => {
  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>Vision & Mission</h2>
        </div>
        <div className="testimonial-row">
  <p>
    To lead South Africa's digital transformation by delivering
    innovative, sustainable, and impactful ICT solutions that connect
    people, empower communities, and foster economic and social growth.
    Through cutting-edge technologies, expert networking solutions, and
    strong partnerships, we aim to create a future where seamless
    connectivity and smart solutions drive inclusivity, sustainability,
    and progress for all.
  </p>
</div>

      </div>
    </div>
  );
};
