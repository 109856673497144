import React from "react";

export const Services = (props) => {
  // Split the data into two rows
  const firstRow = props.data?.slice(0, 3); // First 3 items
  const secondRow = props.data?.slice(3); // Remaining items

  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>
            Kanotech offers a wide range of ICT solutions, including network
            design and optimization, mobile and wireless connectivity, smart
            home and IoT technologies, and site infrastructure services. We
            empower businesses and communities with cutting-edge, sustainable,
            and reliable communication solutions tailored to their needs.
          </p>
        </div>
        {/* First Row */}
        <div className="row">
          {firstRow?.map((d, i) => (
            <div key={`${d.name}-${i}`} className="col-md-4 service-item">
              <i className={d.icon}></i>
              <div className="service-desc">
                <h3>{d.name}</h3>
                <p>{d.text}</p>
              </div>
            </div>
          ))}
        </div>
        {/* Second Row */}
        <div className="row">
          {secondRow?.map((d, i) => (
            <div key={`${d.name}-${i}`} className="col-md-4 service-item">
              <i className={d.icon}></i>
              <div className="service-desc">
                <h3>{d.name}</h3>
                <p>{d.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
